import React, { useState, useEffect, useRef } from 'react'
import { PageProps } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import { Head, Lightbox, Link, Form } from '~/components'
import { Image } from '~/components/Lightbox'
import * as st from '~/assets/styl/Home.module.styl'
import cn from 'classnames'
import logo from '~/assets/svg/logo.svg'
import logoPedraBranca from '~/assets/svg/logo-pedra-branca.svg'
import logoEurotec from '~/assets/svg/logo-eurotec.svg'
import logoHogar from '~/assets/svg/logo-hogar.svg'
import imgTop from '~/assets/img/bkg-top.png'
import imgHome01 from '~/assets/img/img-home-01.png'
import imgHome02 from '~/assets/img/img-home-02.png'
import imgHome03 from '~/assets/img/img-home-03.png'
import imgHome04 from '~/assets/img/img-home-04.png'
import imgHome05 from '~/assets/img/img-home-05.png'
import imgHome06 from '~/assets/img/img-home-06.png'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollTrigger as ScrollTriggerClasses } from 'scrolltrigger-classes'

gsap.registerPlugin(ScrollTrigger, ScrollTriggerClasses)

const imgLightBox = [
  {
    url: imgHome01,
    legend: 'Imagem aérea da localização do empreendimento Pátio das Artes',
  },
  {
    url: imgHome02,
    legend:
      'Imagem meramente ilustrativa. Incorporação imobiliária sob o número R-1 - 109.661',
  },
  {
    url: imgHome03,
    legend: 'Pátio interno com piscina | Imagem meramente ilustrativa.',
  },
  {
    url: imgHome04,
    legend: 'Spa | Imagem meramente ilustrativa.',
  },
  {
    url: imgHome05,
    legend: 'Hall de Entrada | Imagem meramente ilustrativa.',
  },
  {
    url: imgHome06,
    legend:
      'Tipologia 3 suítes de 153 m2 | Projeto de interiores desenvolvido pela Arq. Beatriz Zeglin Arquitetura',
  },
]

const Home = ({ location }: PageProps) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  const sectionRef = useRef(null)
  const imgRef = useRef(null)
  const towersListRef = useRef(null)

  useEffect(() => {
    const sectionElement = sectionRef.current
    const imgElement = imgRef.current
    const towersListElement = towersListRef.current

    // Animação para a seção
    gsap.from(sectionElement, {
      opacity: 0,
      y: 100,
      duration: isMobile ? 0.8 : 1,
      scrollTrigger: {
        trigger: sectionElement,
        start: isMobile ? 'top 95%' : 'top 80%',
      },
    })

    // Animação para a imagem
    gsap.from(imgElement, {
      opacity: 0,
      duration: isMobile ? 0.8 : 1,
      scrollTrigger: {
        trigger: imgElement,
        start: isMobile ? 'top 95%' : 'top 80%',
      },
    })

    // Animação para a lista de torres
    gsap.from(towersListElement, {
      opacity: 0,
      y: 100,
      duration: isMobile ? 1.5 : 2,
      scrollTrigger: {
        trigger: towersListElement,
        start: isMobile ? 'top 95%' : 'top 80%',
      },
    })
  }, [])

  const btnPulseRef = useRef(null)

  useEffect(() => {
    const pulses = btnPulseRef.current.getElementsByClassName('pulse')

    const interval = setInterval(() => {
      for (let i = 0; i < pulses.length; i++) {
        pulses[i].style.animationDelay = `${i * 0.2}s`
      }
    }, 100)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const [buttonIsVisible, setButtonIsVisible] = useState(true)

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset
    setButtonIsVisible(scrollPosition < 30)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const [index, setIndex] = useState(0)
  const [images, setImages] = useState<Array<Image>>()

  return (
    <>
      <Head location={location} />
      <header className={st.header}>
        <img src={logo} alt="Logo do empreendimento Pátio das Artes" />
        <figure>
          <img
            src={imgTop}
            className={st.imgDesk}
            alt="Imagem meramente ilustrativa. Incorporação imobiliária sob o número R-1 - 109.661"
          />
          <figcaption>
            Imagem meramente ilustrativa. Incorporação imobiliária sob o número
            R-1 - 109.661
          </figcaption>
        </figure>
        <Link href="#explorar" className={!buttonIsVisible && st.opacity}>
          <span>Conheça o projeto</span>
          <div ref={btnPulseRef}>
            <div className="pulse"></div>
            <div className="pulse"></div>
            <div className="pulse"></div>
          </div>
        </Link>
      </header>
      <main className={st.core} id="explorar">
        <div className={cn(st.flex, 'container container-video')} ref={sectionRef}>
          <section className="text-with-video">
            <h1 className="title large-size color-black">
            Sucesso de Vendas.{' '}
            </h1>
            <p>
              Com imensa satisfação e gratidão, celebramos mais um extraordinário marco em nossa trajetória de sucesso!
            </p>
            <p>
              A Pedra Branca agradece a todos os clientes e corretores do mercado imobiliário que acreditaram que seria possível vender 392 unidades com 148 plantas diferentes em apenas 6 dias.
            </p>
            <p>
              Pátio das Artes: a arte de viver o melhor da Pedra Branca.
            </p>
          </section>
          <div className={st.videoContainer}>
            <iframe
              src="https://www.youtube.com/embed/OZrGx0s9LYs?rel=0&autoplay=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
        <div className="container-full">
          <img
            src={imgHome01}
            alt="Imagem aérea da localização do empreendimento Pátio das Artes"
            onClick={() => {
              setIndex(index)
              setImages(imgLightBox.filter((_, index) => index === 0))
            }}
          />
        </div>
        <div className={cn(st.flex, 'container')} ref={sectionRef}>
          <section>
            <h1 className="title large-size color-black">
              A arte de viver o melhor{' '}
              <span className="block">da Pedra Branca.</span>
            </h1>
            <p>
              A Pedra Branca inova mais uma vez e traz para a Cidade Criativa o
              lançamento mais icônico da sua história: um empreendimento único,
              que proporciona morar, trabalhar, estudar e se divertir, tudo ao
              alcance de uma caminhada. Um viver sustentável, com qualidade de
              vida e mais tempo para você curtir os bons momentos.
            </p>
            <p>
              Ocupando toda uma quadra no Passeio Pedra Branca, o Pátio das
              Artes dialoga com o bairro e, com sua arquitetura exuberante,
              torna o ambiente ainda mais vivo e vibrante.
            </p>
            <p>
              Formado predominantemente por uso residencial, o empreendimento
              conta também com lojas no térreo e apartamentos-jardins. Além das
              unidades residenciais e das lojas, a principal esquina do Pátio
              das Artes abriga uma grande praça coberta, ocupada pela
              Mercadoteca.
            </p>
          </section>
          <Link href="#saiba-mais" className="btn btn-white">
            Conheça o projeto
          </Link>
        </div>
        <div className="container" ref={imgRef}>
          <figure>
            <img
              src={imgHome02}
              alt="Imagem meramente ilustrativa. Incorporação imobiliária sob o número R-1 - 109.661"
              onClick={() => {
                setIndex(index)
                setImages(imgLightBox.filter((_, index) => index === 1))
              }}
            />
            <figcaption>
              Imagem meramente ilustrativa. Incorporação imobiliária sob o
              número R-1 - 109.661
            </figcaption>
          </figure>
        </div>
        <section className="container" ref={towersListRef}>
          <h2 className="title large-size color-black text-center">
            A arte de aproveitar a vida.
          </h2>
          <div className={st.videoContainer}>
            <iframe
              src="https://www.youtube.com/embed/T4xrx2bu_cw?rel=0&autoplay=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
          {/* <ul className={st.towersList}>
            <li>
              <section>
                <h3 className="title color-black">
                  torre <span className="uppercase">corpo</span>
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore aliquip
                  ex ea commodo consequat.
                </p>
                <a href="" className="btn btn-white custom-padding">
                  conheça a torre
                </a>
              </section>
            </li>
            <li>
              <section>
                <h3 className="title color-black">
                  torre <span className="uppercase">espaço</span>
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore aliquip
                  ex ea commodo consequat.
                </p>
                <a href="" className="btn btn-white custom-padding">
                  conheça a torre
                </a>
              </section>
            </li>
            <li>
              <section>
                <h3 className="title color-black">
                  torre <span className="uppercase">matéria</span>
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore aliquip
                  ex ea commodo consequat.
                </p>
                <a href="" className="btn btn-white custom-padding">
                  conheça a torre
                </a>
              </section>
            </li>
            <li>
              <section>
                <h3 className="title color-black">
                  torre <span className="uppercase">tempo</span>
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore aliquip
                  ex ea commodo consequat.
                </p>
                <a href="" className="btn btn-white custom-padding">
                  conheça a torre
                </a>
              </section>
            </li>
          </ul> */}
        </section>
        <div className="container-full">
          <figure>
            <img
              src={imgHome03}
              alt="Pátio interno com piscina | Imagem meramente ilustrativa."
              onClick={() => {
                setIndex(index)
                setImages(imgLightBox.filter((_, index) => index === 2))
              }}
            />
            <figcaption>
              Pátio interno com piscina | Imagem meramente ilustrativa.{' '}
            </figcaption>
          </figure>
        </div>
        <div className={cn(st.flex, st.reverse, 'container')}>
          <section>
            <h2 className="title large-size color-black">
              A arte de se encontrar.
            </h2>
            <p className={st.customPadding}>
              “O Pátio das Artes é, ao mesmo tempo, prédio e cidade, que abriga
              moradores e recebe visitantes para usos mais efêmeros ou mais
              prolongados. É a continuidade do bairro, mistura usos comerciais e
              residenciais, escritórios e residências, pequenos studios e
              grandes apartamentos. Os escalonamentos das torres, mais do que
              uma atitude estética, criam inúmeros terraços, que são como a
              continuidade das generosas vias do bairro, são praças suspensas
              com exuberante vegetação, que está presente no térreo, nas
              fachadas no pátio interno e nos gardens suspensos.”
            </p>
            <p>Rodrigo Marcondes Ferraz | FGMF Arquitetura</p>
          </section>
          <Link href="#saiba-mais" className="btn btn-white custom-font-mobile">
            Conheça o projeto
          </Link>
        </div>
        <div className="container-full">
          <figure>
            <img
              src={imgHome04}
              alt="Spa | Imagem meramente ilustrativa."
              onClick={() => {
                setIndex(index)
                setImages(imgLightBox.filter((_, index) => index === 3))
              }}
            />
            <figcaption>Spa | Imagem meramente ilustrativa.</figcaption>
          </figure>
        </div>
        <div className={cn(st.flex, 'container')}>
          <section>
            <h2 className="title large-size color-black">Arte revelada</h2>
            <p>
              O Pátio das Artes conta com pinturas de Rubens Oestroem, produções
              artísticas tridimensionais de Clara Fernandes, escultura
              customizada por Cazão, esculturas lúdicas de Sara Rosenberg e
              rascunhos da obra Tarot de Rodrigo de Haro. A curadoria de Rubens
              Oestroem e Clara Fernandes é da galerista Helena Fretta e da
              historiadora Thays Tonin, doutora em História da Arte, experientes
              pesquisadoras do universo artístico de Santa Catarina e do país.
            </p>
          </section>
          <Link href="#saiba-mais" className="btn btn-white custom-font-mobile">
            Conheça o projeto
          </Link>
        </div>
        <div className="container no-margin">
          <figure>
            <img
              src={imgHome05}
              alt="Hall de Entrada | Imagem meramente ilustrativa."
              onClick={() => {
                setIndex(index)
                setImages(imgLightBox.filter((_, index) => index === 4))
              }}
            />
            <figcaption>
              Hall de Entrada | Imagem meramente ilustrativa.
            </figcaption>
          </figure>
        </div>
        <div className={cn(st.flex, 'container')}>
          <section>
            <h2 className="title large-size color-black">
              Onde a arte <span className="block">encontra a vida.</span>
            </h2>
            <p className={st.customPadding}>
              &quot;O Pátio das Artes é um empreendimento inovador em conceito e
              produto. Apresenta uma nova forma de morar, que privilegia as
              áreas sociais, de convivência e integração dentro das unidades -
              rodeadas por arte!&quot;
            </p>
            <p>BlasiBahia + Arquitetos Associados</p>
          </section>
          <Link
            href="#saiba-mais"
            className="btn btn-white custom-padding custom-font-mobile"
          >
            Visite o apartamento decorado
          </Link>
        </div>
        <div className="container-full no-margin">
          <figure>
            <img
              src={imgHome06}
              alt="Tipologia 3 suítes de 153 m2 | Projeto de interiores desenvolvido pela Arq. Beatriz Zeglin Arquitetura"
              onClick={() => {
                setIndex(index)
                setImages(imgLightBox.filter((_, index) => index === 5))
              }}
            />
            <figcaption>
              Tipologia 3 suítes de 153 m2 | Projeto de interiores desenvolvido
              pela Arq. Beatriz Zeglin Arquitetura
            </figcaption>
          </figure>
        </div>
        {isMobile && (
          <Lightbox
            index={index}
            images={images}
            setImages={setImages}
            setIndex={setIndex}
          />
        )}
      </main>
      <footer className={st.footer}>
        <div id="saiba-mais" className={st.flexCenter}>
          <img
            src={logo}
            alt="Logo do empreendimento Pátio das Artes"
            className={st.logo}
          />
          <section>
            <h2 className="title medium-size text-center color-white">
              Venha viver o melhor{' '}
              <span className="block">da Pedra Branca.</span>
            </h2>
            <Form
              inputs={[
                {
                  name: 'name',
                  placeholder: 'nome',
                  type: 'text',
                  required: true,
                },
                {
                  name: 'email',
                  placeholder: 'email',
                  type: 'email',
                  required: true,
                },
                {
                  name: 'telefone',
                  placeholder: 'telefone',
                  type: 'text',
                  mask: 'phone',
                  required: true,
                },
              ]}
              button={
                <button type="submit" className="btn btn-green">
                  enviar
                </button>
              }
              loadingButton={
                <button className="btn btn-green" disabled>
                  enviando...
                </button>
              }
              idPrefix={'contact'}
              path={'conversions'}
            />
            <p className="color-white text-center">
              &quot;Para prosseguir com esta operação, você deverá fornecer
              alguns dados pessoais. Mas não se preocupe, a Pedra Branca cuida
              da sua privacidade! Os dados pessoais serão armazenados e tratados
              conforme nossa política de privacidade. A qualquer tempo você
              poderá solicitar remoção dos seus dados, para demais informações{' '}
              <Link
                href="https://www.cidadepedrabranca.com.br/lgpd"
                external
                title="Ir para a página sobre a LGPD da Pedra Branca Cidade Criativa"
                className="color-green"
              >
                clique aqui!
              </Link>
              &quot;
            </p>
          </section>
          <div className={st.logos}>
            <img
              src={logoPedraBranca}
              alt="Logo da Pedra Branca - Cidade Criativa"
            />
            <img src={logoEurotec} alt="Logo da Eurotec Group" />
            {/* <img src={logoHogar} alt="Logo da Hogar Empreendimentos" /> */}
          </div>
        </div>
        <Link
          href="https://www.tiki.com.br/"
          external
          title="Ir para a página da Tiki, empresa que desenvolveu este website"
          className="font-tiki text-center color-white"
        >
          Tiki
        </Link>
      </footer>
    </>
  )
}

export default Home
