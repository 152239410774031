// extracted by mini-css-extract-plugin
export var core = "Home-module--core--rS7PR";
export var customPadding = "Home-module--custom-padding--ycGFT";
export var flex = "Home-module--flex--yzEmh";
export var flexCenter = "Home-module--flex-center--b8q72";
export var footer = "Home-module--footer--N4edH";
export var header = "Home-module--header--lcycU";
export var imgDesk = "Home-module--img-desk--GIDRa";
export var logo = "Home-module--logo--m4MQS";
export var logos = "Home-module--logos--FnmFX";
export var opacity = "Home-module--opacity--mVemJ";
export var reverse = "Home-module--reverse--0pXSb";
export var towersList = "Home-module--towers-list--RHA10";
export var videoContainer = "Home-module--video-container--Pm-Pd";